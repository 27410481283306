<template>
  <component
    :is="tag"
    role="link"
    :to="to"
    :href="href ? href : !to ? '#' : undefined"
    :target="target"
  >
    <slot />
  </component>
</template>

<script setup>
import { NuxtLink } from '#components'

const props = defineProps({
  to: {
    type: [String, Object],
    default: undefined,
  },

  href: {
    type: String,
    default: undefined,
  },

  target: {
    type: String,
    default: '_self',
  },
})
const tag = computed(() => (props.to ? NuxtLink : 'a'))
</script>
